import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import auth from "./auth";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { createProvider } from "./vue-apollo";
import i18n from "./i18n";

const vuetifyOptions = {};
Vue.use(Vuetify);

Vue.config.productionTip = false;

Vue.use(auth);

Vue.$keycloak
  .init({ onLoad: "login-required", checkLoginIframe: false })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      const userid = Vue.$keycloak.tokenParsed.preferred_username;
      store.dispatch("Auth/loginUser", {
        user: { name: userid },
        token: Vue.$keycloak.token,
      });
      console.log("Authenticated");
      new Vue({
        router,
        store,
        vuetify: new Vuetify(vuetifyOptions),
        apolloProvider: createProvider(),
        i18n,
        render: (h) => h(App),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      Vue.$keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  Vue.$keycloak.tokenParsed.exp +
                    Vue.$keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch((err) => {
    console.error(err);
    console.error("Authenticated Failed");
  });
