<template>
  <div class="containerS">
    <boost v-if="!isVertical"></boost>
    <div class="sliderContainer">
      <input
        v-on:touchend="touchend"
        v-on:mouseup="touchend"
        v-on:mousedown="touchstart"
        v-on:touchstart="touchstart"
        :style="this.cssVars()"
        type="range"
        min="-100"
        max="100"
        class="slider"
        :value="value"
        v-on:input="updateValue($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import Boost from "../actions/boost.vue";

export default {
  name: "Slider",
  data() {
    return {
      value: 0,
      mode: "end",
      resetTimout: null,
    };
  },
  components: {
    Boost,
  },
  props: {
    primaryColor: String,
    secondaryColor: String,
    accentColor: String,
    isVertical: Boolean,
  },
  methods: {
    touchstart: function () {
      this.mode = "move";
      clearTimeout(this.resetTimout);
      this.updateValue(this.value);
    },
    touchend: function () {
      this.mode = "end";
      this.resetTimout = setTimeout(() => {
        this.updateValue(0);
      }, 0);
    },
    updateValue: function (value) {
      this.value = value;
      this.$emit("sliderInput", {
        mode: this.mode,
        value: value,
      });
    },
    cssVars: function () {
      var rgbaPrimary = this.hexToRGBa(this.primaryColor, 0.5);
      var thumbRotation;
      var sliderRotate;
      if (this.isVertical == true) {
        sliderRotate = 270;
        thumbRotation = 90;
      } else {
        sliderRotate = 0;
        thumbRotation = 90;
      }
      return {
        "--slider-rotation": "rotate(" + sliderRotate + "deg)",
        "--bg-primary-color": rgbaPrimary,
        "--button-svg":
          "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 187.68 187.68' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:" +
          encodeURIComponent(this.secondaryColor) +
          "%3B%7D.cls-2%7Bfill:" +
          encodeURIComponent(this.rgbAccent) +
          "%3Bopacity:.5%3B%7D.cls-3%7Bfill:" +
          encodeURIComponent(this.primaryColor) +
          "%3Bfilter:url(%23drop-shadow-1)%3B%7D%3C/style%3E%3Cfilter id='drop-shadow-1' filterUnits='userSpaceOnUse'%3E%3CfeOffset dx='0' dy='0'/%3E%3CfeGaussianBlur result='blur' stdDeviation='5'/%3E%3CfeFlood flood-opacity='.2'/%3E%3CfeComposite in2='blur' operator='in' result='result1'/%3E%3CfeComposite in='SourceGraphic' in2='result1'/%3E%3C/filter%3E%3C/defs%3E%3Ccircle transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-3' cx='93.84' cy='93.84' r='78.64'/%3E%3Cpolygon transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-1' points='93.84 23.45 61.34 36.39 126.34 36.39'/%3E%3Cpolygon transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-1' points='93.84 165.77 61.34 152.82 126.34 152.82'/%3E%3Crect transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-2' x='49.34' y='91.73' width='89' height='5.74' rx='2.87' ry='2.87'/%3E%3Crect transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-2' x='55.22' y='70.67' width='77.23' height='5.74' rx='2.87' ry='2.87'/%3E%3Crect transform%3D%22rotate(" +
          thumbRotation +
          "%2C%2094%2C%2094)%22 class='cls-2' x='55.22' y='112.8' width='77.23' height='5.74' rx='2.87' ry='2.87'/%3E%3C/svg%3E\")",
      };
    },
    hexToRGBa: function (val, opacity = 1) {
      var newval = val.replace("#", "").replace(/\s+/g, "");
      if (newval.length == 3) {
        newval = newval
          .split("")
          .map((item) => {
            if (item == "#") {
              return item;
            }
            return item + item;
          })
          .join("");
      } else if (newval.length != 6) {
        throw "Only six-digit hex colors are allowed.";
      }
      var aRgbHex = newval.match(/.{1,2}/g);
      return (
        "rgba(" +
        parseInt(aRgbHex[0], 16) +
        "," +
        parseInt(aRgbHex[1], 16) +
        "," +
        parseInt(aRgbHex[2], 16) +
        "," +
        opacity +
        ")"
      );
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/controls/Slider.css";
</style>
