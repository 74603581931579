import gql from "graphql-tag";

export const getPlayerStats = gql`
  query Query($getPlayerStatsId: ID!) {
    getPlayerStats(id: $getPlayerStatsId) {
      id
      name
      bestTime
      bestLap
    }
  }
`;
export const getBestTimeRanking = gql`
  query Query {
    getBestTimeRanking {
      id
      name
      dayRank
      globalRank
      bestLap
      bestTime
    }
  }
`;

export const getDayRanking = gql`
  query GetDayRanking($date: Int!) {
    getDayRanking(date: $date) {
      name
      bestLap
      bestTime
    }
  }
`;
