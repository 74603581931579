const state = {
  controls: {
    left: {
      mode: 0,
    },
    right: {
      mode: 0,
    },
  },
};
const getters = {};
const actions = {};
const mutations = {
  setControllerMode(state, place, mode) {
    state.controller[place].mode = mode;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
