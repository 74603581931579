<template>
  <div class="locale-switcher">
    <v-select
      v-model="$i18n.locale"
      :items="locales"
      item-text="name"
      item-value="code"
      :label="this.$t('settings.language')"
    >
    </v-select>
  </div>
</template>
<script>
import { getSupportedLocales } from "@/services/i18n/supported-locales";
export default {
  data: () => ({ locales: getSupportedLocales() }),
};
</script>
