const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });
const suffixes = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
};
export function ordinal(number) {
  const suffix = suffixes[english_ordinal_rules.select(number)];
  return number + suffix;
}

export function msToString(ms) {
  let mstr = (Math.floor(Math.floor(ms / 1000) / 60) % 60)
    .toString()
    .padStart(2, "0");
  let sstr = (Math.floor(ms / 1000) % 60).toString().padStart(2, "0");
  let msstr = (ms % 1000).toString().padStart(3, "0");
  return mstr + ":" + sstr + ":" + msstr;
}

export function bestLap(laps) {
  if (laps.length === 0) {
    return 0;
  }
  let bestLap = laps[0];
  laps.forEach((lap) => {
    if (lap < bestLap) {
      bestLap = lap;
    }
  });
  return bestLap;
}

export function sumLaps(laps) {
  if (laps.length === 0) {
    return 0;
  }
  return laps.reduce((a, n) => a + n);
}
